import * as firebase from 'firebase/app'
import {
    getAuth,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    signInAnonymously,
    signInWithEmailAndPassword,
    setPersistence,
    browserLocalPersistence,
} from "firebase/auth";

import store from '../store'
import router from "../router"
import api from "../utils/api"
            
const auth = getAuth();

export default class Auth {
    static checkAuth = (success: any, failure: any) => {
        // const provider = new firebase.auth.GoogleAuthProvider();

        // const user = firebase.auth();
        try {
            onAuthStateChanged(
                auth, 
                user => {
                    if (user) {
                        user.getIdToken(false).then(async (idToken) => {
                            store.dispatch('setTokenId', idToken);
                            await api.getUser();
                            // console.log(`${user.displayName} logged in`);
                            // console.debug(JSON.stringify(user));
                            success(idToken);
                        });
                        // User is signed in.
                    } else {
                        // No user is signed in.
                        console.log('no user logged in');
                        failure();
                    }
                }
            )
        } catch (error) {
            console.log(error);
            failure();
        }
    };
    static saveTokenId = () => {
        const user = auth.currentUser;
        if (user) {
            user.getIdToken(false).then(function (idToken) {
                const tokenID = idToken;
                store.dispatch('setTokenId', idToken);
                console.debug("Token ID saved...." + tokenID);
                return true;
            }).catch(function (error) {
                console.debug("Token ID not found....");
            });
        } else {
            store.dispatch('logout');
            console.debug("Logged out!");
        }
        return false;
    };

    static googleLogin = (redirect:string) => {
        const provider = new GoogleAuthProvider();
        if (redirect.length === 0) {
            redirect = "/";
        }
        signInWithPopup(auth, provider)
            .then (result => {
                const user = result.user;
                Auth.saveTokenId();
                router.push(redirect);
            })
            .catch(console.log)
    }
    static googleLogout = () => {
        console.debug("Log out");
        // let tokenID = "";
        signOut(auth).then(() => {
            Auth.saveTokenId();
        }).catch(err => {
            console.log("Could not logout: ", err)
        });
    };

    static anonLogin = (name: string, redirect: string) => {
        if (redirect.length === 0) {
            return;
        }
        signInAnonymously(auth)
            .then(result => {
                const user = result.user;
                Auth.saveTokenId();
                let url = new URL(window.location.origin + redirect);
                url.searchParams.set("name", name);
                router.push(url.pathname + "?" + url.searchParams.toString());
            })
            .catch(console.log)
    }

    static eLogin = (email: string, password: string) => {
        setPersistence(auth, browserLocalPersistence).then(() => {
            return signInWithEmailAndPassword(auth, email, password)
                .then(result => {
                    const user = result.user;
                    Auth.saveTokenId();
                    router.push("/");
                })
                .catch(console.log)
        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
        });
    }

}
