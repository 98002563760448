import store from '../store'
import axios from 'axios'
import defaults from './defaults';
import router from '../router'
import _ from 'lodash';
import ashttp from "./ashttp";

import * as firebase from 'firebase/app'
// import 'firebase/storage';
// import 'firebase/database';
import Auth from './auth';
import { getAnalytics, logEvent } from "firebase/analytics";
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getDatabase, ref as RDBref, set } from "firebase/database";

axios.interceptors.response.use((response) => {
    return response
  },
  function (error) {
    let originalRequest = error.config;
    try {
      if (error.response.status === 401 && !originalRequest._retry) {
        console.log("Got 401, so resending to login after a CHECK AUTH ");
        Auth.checkAuth(
          (newToken: string) => {
            console.log("SUCCEEDED RE-AUTH");
            // TODO Auth retry with new token
            if (!originalRequest.headers.aihearuRetry) {
              originalRequest.headers.Authorization = 'Bearer ' + newToken;
              originalRequest.headers.aihearuRetry = true;
              return axios.request(originalRequest);
            } else {
              router.push("/login");
            }
          },
          () => { router.push("/login") }
        );
      } else {
        throw error;
      }
    } catch (err) {
      console.error("Got an Error other than 401: ", err);
      throw error;
    }
  });
const fbConfigDev = {
  apiKey: "AIzaSyD9HglQzj8dEhaLSXss-ctBn7gJ7JWNo68",
  authDomain: "ihearu-dev.firebaseapp.com",
  databaseURL: "https://ihearu-dev.firebaseio.com",
  projectId: "ihearu-dev",
  storageBucket: "ihearu-dev.appspot.com",
  messagingSenderId: "942734562719",
  appId: "1:942734562719:web:0967804909436c70d199aa",
  measurementId: "G-RKZC3YJR5X"
};

const fbConfigProd = {
  apiKey: "AIzaSyAcpRdRFKyQg2PC3aSWZB2M8YmV7ZMJw_g",
  authDomain: "ihearu-beta.firebaseapp.com",
  databaseURL: "https://ihearu-beta.firebaseio.com",
  projectId: "ihearu-beta",
  storageBucket: "ihearu-beta.appspot.com",
  messagingSenderId: "386703282732",
  appId: "1:386703282732:web:ed098751f3288cd5f93789"
};
let firebaseApp;
if (defaults.projectBasedOnUrl() === "ihearu-beta") {
  firebaseApp = firebase.initializeApp(fbConfigProd);
} else {
  firebaseApp = firebase.initializeApp(fbConfigDev);
}

const storage = getStorage(firebaseApp);
let RDB = getDatabase(firebaseApp);
const analytics = getAnalytics();

let rootPath = "http://localhost:5001/ihearu-dev/us-central1/app";

if (defaults.projectBasedOnUrl() !== "localhost") {
  rootPath = "https://us-central1-" + defaults.projectBasedOnUrl() + ".cloudfunctions.net/app";
}
// SHOULD BE COMMENTED OUT
// HARDCODED for dev testing on prod functions
// rootPath = "http://localhost:5001/ihearu-dev/us-central1/app";

console.log("APIs initialized: " + rootPath);

function get (url = '', abs = false) {
  console.debug("Getting " + url);
  if (!abs) {
    url = rootPath + url; 
  }
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + store.getters.getTokenId,
    }
  });
}

// function aihearuPost (url = '', data:any ) {
//   return axios.post(
//     url, 
//     data, {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': 'Bearer ' + store.getters.getTokenId,
//       }
//   });
// }

function post (url = '', data:any ) {
  return axios.post(
    rootPath + url, 
    data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.getters.getTokenId,
      }
  });
}

function put (url = '', data:any ) {
  return axios.put(
    rootPath + url, 
    data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.getters.getTokenId,
      }
  });
}

function HttpDelete (url = '') {
  return axios.delete(rootPath + url, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + store.getters.getTokenId,
    }
  });
}

function saveUser(user: any) {
  if (!user.engines) {
    user.engines = defaults.user.engines;
  }
  if (!user.speaker) {
    user.speaker = user.uid;
  }
  store.dispatch('setUser', user);

  // TODO this needs to be otimized
  savePublicInfo(user.uid, false);

  if ((user["admin-uid"] !==undefined) && (user["admin-uid"] !==user.uid)){
    savePublicInfo(user["admin-uid"], false);
  }

  if ((user.speaker !==undefined) ){
    savePublicInfo(user.speaker, false);
  } 
}

function savePublicInfo(uid:string, force: boolean) {
  if (uid ===defaults.user.uid) {
    return;
  }
  if (!store.getters.getPublicInfo.has(uid) || force) {
    api.getUserByUid(uid);
  }
}

export default class api {
  static getUserByUid = (uid: string) => {
    get('/api/user/' + uid).then((res) => {
      store.dispatch("setPublicInfo", res.data);
      return;
    });
  };

  static refreshSpeaker = () => {
    api.getUserByUid(store.getters.getUser.speaker);
  };

  static getUser = () => {
    console.debug("Get User called!!");
    
    get('/api/user')
      .then(async response => {
        let user = response.data;
        if (!Object.prototype.hasOwnProperty.call(user, "uid") && store.getters.getUser.retry < 10000) {
          let retry = 1000 + store.getters.getUser.retry * 2;
          // console.log("🚀 ~ file: api.ts ~ line 178 ~ api ~ retry", retry)
          user.retry = retry;
          saveUser(user);
          await new Promise(resolve => setTimeout(resolve, retry));
          api.getUser();
        } else {
          user.retry = 0;
          saveUser(user);
          api.getSpeakers();
          if (user.anon) {
            console.log("ANON user found");
            let url = new URL(window.location.href);
            let urlParams = new URLSearchParams(url.search);
            if (urlParams.has("name")) {
              api.setDisplayName(urlParams.get("name") || "Anonymous", () => { }, () => { });
              urlParams.delete('name');
              router.push(url.pathname + "?" + urlParams.toString());
              console.log("Removed name from URL params " + url.searchParams.toString())
            }
          }
        }
        // console.debug( "get response:" + JSON.stringify(response.data));
      })
      .catch(err => { 
        return { error: true }; 
      });
  };

  static setDisplayName = function (displayName: string = "Anon", success: () => void, err: () => void) {
    post('/api/user', {
      displayName: displayName
    }).then(() => {
      if (store.getters.getUser.displayName !==displayName) {
        api.getUser();
        success();
      }
    }).catch((e) => {
      console.error("Failed to set setDisplayName", e);
      err();
    });
  };

  static setAdvanced = function (advanced: boolean, success: () => void, err: () => void) {
    post('/api/user', {
      advanced: advanced
    }).then(() => {
        api.getUser();
        success;
    }).catch((err) => {
      console.error("Failed to set Advanced");
      err;
    });
  };
  
  static setBuddyAPB = function (buddyAPB: boolean, success: () => void, err: () => void) {
    post('/api/user/' + store.getters.getUser.speaker, {
      buddyAPB: buddyAPB
    }).then(() => {
      api.refreshSpeaker();
      success;
    }).catch((err) => {
      console.error("Failed to set Advanced");
      err;
    });
  };

  static inferBlob = function (url: string, file:any) {

    return axios( {
      method: "POST",
      url: url, 
      headers: {
        'content-type': `multipart/form-data; boundary=asdfasdfasdf`,
        'Authorization': 'Bearer ' + store.getters.getTokenId,
        },
    })
  }

  static uploadAudioForDecode = function (file: any, hash: string) {
    const url = store.getters.getSpeakerEngines["My"].url + "upload?hash=" + hash + "&uid=" + store.getters.getSpeaker.uid;
    let form = new FormData();
    form.append("file", file);

    return ashttp.post( url, form );
  }

  static postDirectText = function (text: string) {
    const url = store.getters.getSpeakerEngines["My"].url + "setText?text=" + text + "&uid=" + store.getters.getSpeaker.uid;
      return ashttp.post(url, {} );
  }

  static getZoomOTP = function () {
    const url = store.getters.getSpeakerEngines["My"].url + "otp/zoom?uid=" + store.getters.getSpeaker.uid;
    return ashttp.get( url );
  }

  static resetZoomOTP = function () {
    const url = store.getters.getSpeakerEngines["My"].url + "otp/zoom?uid=" + store.getters.getSpeaker.uid;
    return ashttp.delete( url );
  }

  static sendAudioUrlForDecode = function (audioUrl: string, hash: string) {
    const url = store.getters.getSpeakerEngines["My"].url + "upload?hash=" + hash + "&uid=" + store.getters.getSpeaker.uid;

    return ashttp.post(
      url,
      {},
      {
        'content-type': `multipart/form-data; boundary=aihearuMultiPartBoundary`,
        'url': audioUrl
      }
    )
  }

  static inferBlobCustom = function (file:any) {
    let form = new FormData();
    form.append("file", file);

    return ashttp.post( 
      `${store.getters.getUser.ihearuSTT_custom_url}`,
      form,
      {
        'content-type': `multipart/form-data; boundary=aihearuMultiPartBoundary`,
      }
    );
  }

  static getDownloadUrl = async function (url: string) {
    try {
      const storageFileRef = ref(storage, url);
      let durl = await getDownloadURL(storageFileRef);
      console.log("🚀 ~ file: api.ts ~ line 299 ~ api ~ getDownloadUrl", durl);
      return durl;
    } catch (err) {
      console.error("🚀 ~ file: api.ts ~ line 302 ~ api ~ getDownloadUrl:", err);
    }
  }

  static uploadBlob = function (file: any, filename: string, onComplete: (arg0: any) => void, onError: (arg0: any) => void) {
    // TODO should we upload within same user or speaker?
    let storageFileRef = ref(storage, `userData/${store.getters.getSpeaker.uid}/${filename}`);
    
    const task = uploadBytesResumable(storageFileRef, file);
        task.on('state_changed',
        function progress(snapshot: any) {
            let per = (snapshot.bytesTransferred/snapshot.totalBytes) * 100;
            console.log(" upload progress: " + per);
            // uploader.value = per;
        },
        function error(err: any) {
            console.log("Error: ", err);
            onError(err);
        },

        function complete() {
            console.log("Complete ");
            // fileButton.value = "";
            getDownloadURL(task.snapshot.ref).then(function(downloadURL:string) {                   
                console.log('Successfully uploaded! File available at:\n' + downloadURL);
                onComplete(downloadURL);
            });
    });
  }

  static getServer: any = () => {
    return get('/server').then((res) => {
      store.dispatch("setServer", res.data);
    });
  };

  static getRDBNotifyRef: any = (uid: string) => {
    return RDBref(RDB,'notify/' + uid);
  };
  static getRDBChatMsgRef: any = (token: string) => {
    return RDBref(RDB,'share/' + token + '/messages');
  };
  static getRDBChatMetaNamesRef: any = (token: string) => {
    return RDBref(RDB,'share/' + token + '/meta/names');
  };
  static getRDBChatMetaOwnerRef: any = (token: string) => {
    return RDBref(RDB,'share/' + token + '/meta/owner');
  };
  static getAudio: any = (state: string, options: any) => {
    let req = '/api/audio?state=' + state + '&uid=' + store.getters.getUser.speaker;
    
    if (options) {
      if (options.search && options.search.length > 0) {
        req = req + "&search=" + options.search;
      }
      if (options.limit) {
        req = req + "&limit=" + options.limit;
      }
      if (options.endBefore) {
        req = req + '&endBefore=' + options.endBefore;
      } else if (options.startAfter) {
        req = req + '&startAfter=' + options.startAfter;
      } else if (options.startAt) {
        req = req + '&startAt=' + options.startAt;
      }
    }

    return get(req);
  };

  static setAudioState = (id: string, state: string) => {
    return put('/api/audio/'+id, {state:state});
  };
  static updateAudio = (audio: any) => {
    return put('/api/audio/' + audio.id, audio);
  };
  static deleteAudio = (audio: any) => {
    return HttpDelete('/api/audio/' + audio.id);
  };

  static deleteAllAudio = (state: any) => {
    return HttpDelete('/api/audio?state=' + state + '&uid=' + store.getters.getUser.speaker)
  };

  // TODO params should be well defined
  static postAudio = (params: any, onSuccess: (res: any) => void, onError: (err: Error) => void) => {
    params.unique = Math.random();
    console.log("api -> staticpostAudio -> params.unique", params.unique)
    try {
      post('/api/audio/' + store.getters.getSpeaker.uid, params).then((res) => {
        savePublicInfo(store.getters.getSpeaker.uid, true);
        onSuccess(res);
      })
    } catch(err) {
      if (err instanceof Error) {
        onError(err);
      }
    }
  };

  static trainNow = (verified: any, onSuccess: () => void, onError: (err: Error) => void) => {
    try {
      const url = store.getters.getSpeakerEngines["My"].url + "train?uid=" + store.getters.getSpeaker.uid;
      axios.post(
        url
      ).then(onSuccess)
        .catch(onError);
    } catch (err) {
      if (err instanceof Error) {
        onError(err);
      }
    }
  };

  static getPublicInfo = (uid: string, onSuccess: (res:any) => void, onError: (err: Error) => void) => {
    try {
      let userPublicInfo = store.getters.getPublicInfo;
      if (!userPublicInfo.has(uid)) {
        get('/api/user/' + uid).then((res) => {
          return store.dispatch("setPublicInfo", res.data).then(() => onSuccess(store.getters.getPublicInfo));
        });
      } else {
        onSuccess(store.getters.getPublicInfo)
      }
    } catch (err) {
      if (err instanceof Error) {
        onError(err);
      }
    }
  };

  static share = (data: any, onSuccess: () => void, onError: (err: Error) => void) => {
    try {
      data["speaker"] = store.getters.getUser.speaker;
      post(
        "/api/share",
        data
      ).then(() => {
        if (data.ts) {
          api.updateBuddyUpdateHistoryLm(onSuccess, onError);
        } else {
          api.updateBuddyAppendLm("History", data.msg, onSuccess, onError);
        }
      })
        .catch(onError);
    } catch (err) {
      if (err instanceof Error) {
        onError(err);
      }
    }
  };

  static shareDelete = (token: string, onSuccess: () => void, onError: (err: Error) => void) => {
    try {
      HttpDelete(
        "/api/share/"+ token
      ).then(onSuccess)
        .catch(onError);
    } catch (err) {
      if (err instanceof Error) {
        onError(err);
      }
    }
  };

  // TODO currently hard coded to Google
  static assistantActivate = async (type: string, onSuccess: () => void, onError: (err: Error) => void) => {
    const url = store.getters.getSpeakerEngines["My"].url + "assistant/google/auth_url?uid=" + store.getters.getSpeaker.uid;
    ashttp.get(url).then(onSuccess).catch(onError);
  }
  static assistantConfigure = async (type: string, auth_url: string, code: string, onSuccess: () => void, onError: (err: Error) => void) => {
    const url = store.getters.getSpeakerEngines["My"].url + "assistant/google?uid=" + store.getters.getSpeaker.uid;
    ashttp.post(
      url,
      {
        auth_url: auth_url,
        code: code
      }
    )
      .then(onSuccess)
      .catch(onError);
  }
  static assistantDeactivate = async (type: string, onSuccess: () => void, onError: (err: Error) => void) => {
    const url = store.getters.getSpeakerEngines["My"].url + "assistant/google?uid=" + store.getters.getSpeaker.uid;
    ashttp.delete(url).then(onSuccess).catch(onError);
  }
  static assistantTest = async (type: string, query: string, onSuccess: (res:any) => void, onError: (err: Error) => void) => {
    const url = store.getters.getSpeakerEngines["My"].url + "assistant/google/test?uid=" + store.getters.getSpeaker.uid +
    "&query=" + encodeURIComponent(query); 
    ashttp.get(url).then((res) => onSuccess(res)).catch(onError);
  }

  static assistantIsActive = async (type: string, onSuccess: (res:any) => void, onError: (err: Error) => void) => {
    const url = store.getters.getSpeakerEngines["My"].url + "assistant/google?uid=" + store.getters.getSpeaker.uid;
    ashttp.get(url).then((res) => onSuccess(res)).catch(onError);
  }

  static updateBuddyUpdateHistoryLm = async (onSuccess: () => void, onError: (err: Error) => void) => {
    const speaker = store.getters.getSpeaker
    const url = store.getters.getSpeakerEngines["My"].url + "updateBuddy?uid=" + speaker.uid;
    let data: any = {};
    data['set'] = {};

    for (const lmName of _.keys(speaker.lm)) {
      let lm = speaker.lm[lmName]
      if (speaker.lm[lmName].name === "History") {
        let text = await get('/api/user/' + store.getters.getUser.speaker + "/lm/" + lm.id);
        data["set"][lm.name] = {
          text: text.data.text,
          name: lm.name
        }
        console.log("🚀 ~ file: api.ts ~ line 421 ~ api ~ await_.forEach ~ text.data.text", lm.name, text.data.text)
      }
    }

    ashttp.post(
      url,
      data
    ).then(onSuccess)
      .catch(onError);
  }

  static updateBuddyUpdateAllLm = async (onSuccess: () => void, onError: (err: Error) => void) => {
    const speaker = store.getters.getSpeaker
    const url = store.getters.getSpeakerEngines["My"].url + "updateBuddy?uid=" + speaker.uid;
    let data: any = {};
    data['set'] = {};
    data['deleteOthers'] = true;

    for (const lmName of _.keys(speaker.lm)) {
      let lm = speaker.lm[lmName]
      if (lm.id !=="default") {
        let text = await get('/api/user/' + store.getters.getUser.speaker + "/lm/" + lm.id);
        data["set"][lm.name] = {
          text: text.data.text,
          name: lm.name
        }
        console.log("🚀 ~ file: api.ts ~ line 421 ~ api ~ await_.forEach ~ text.data.text", lm.name, text.data.text)
      }
    }
    const verifiedAudio = await api.getAudio("verified");
    data.verifiedAudio = verifiedAudio.data.audio;
    const pendingCorpus = await api.getSpeakerCorpus(false, false);
    let trainLm = "";
    _.forEach(pendingCorpus.data.corpus, (item) => {
      trainLm += item.text + "\n";
    });
    data["set"]["Train"] = {
      text: trainLm,
      name: "Train"
    }
    
    ashttp.post(
      url,
      data
    ).then(onSuccess)
    .catch(onError);
  }
  static updateBuddyTrainLm = async (onSuccess: () => void, onError: (err: Error) => void) => {
    const pendingCorpus = await api.getSpeakerCorpus(false, false);
    let trainLm = "";
    _.forEach(pendingCorpus.data.corpus, (item) => {
      trainLm += item.text + "\n";
    });
    console.log("🚀 ~ file: api.ts ~ line 437 ~ api ~ _.forEach ~ trainLm", trainLm)
    api.updateBuddyUpdateLm("Train", trainLm, "", "", onSuccess, onError);
  }

  static updateBuddyUpdateLm = async (lm: string, text: string, type: string, deleteLm: string, onSuccess: () => void, onError: (err: Error) => void) => {
    const url = store.getters.getSpeakerEngines["My"].url + "updateBuddy?uid=" + store.getters.getSpeaker.uid;
    let data: any = {};

    data[lm] = {
      text: text,
      name: lm,
      type: type
    }

    ashttp.post(
      url,
      {
        set: data,
        delete: [deleteLm]
      }
    ).then(onSuccess)
    .catch(onError);
  }

  static updateBuddyAppendLm = async (lm: string, text: string, onSuccess: () => void, onError: (err: Error) => void) => {
    const url = store.getters.getSpeakerEngines["My"].url + "updateBuddy?uid=" + store.getters.getSpeaker.uid;
    let data: any = {};

    data[lm] = {
      text: text,
      name: lm
    }

    ashttp.post(
      url,
      {
        append: data,
      }
    ).then(onSuccess)
      .catch(onError);
  }

  static updateBuddyDeleteLm = async (lm: string, onSuccess: () => void, onError: (err: Error) => void) => {
    const url = store.getters.getSpeakerEngines["My"].url + "updateBuddy?uid=" + store.getters.getSpeaker.uid;
    ashttp.post(
      url,
      {
        delete: [lm]
      }
    ).then(onSuccess)
      .catch(onError);
  }

  static updateBuddy = async (alsoTrain: boolean, onSuccess: () => void, onError: (err: Error) => void) => {
    try {
      // if (!store.getters.getSpeakerEngines["My"].enabled && !store.getters.getSpeakerEngines["Keywords"].enabled) {
      //   throw new Error("updated buddy failed, as My and Keywords both disabled");
      // }
      let buddyData:any = { train: alsoTrain};

      const draftAudio = await api.getAudio("draft");
      const verifiedAudio = await api.getAudio("verified");
      const keywords = await api.getSpeakerCorpus(true, false);
      const pendingCorpus = await api.getSpeakerCorpus(false, false);

      buddyData.draftAudio = draftAudio.data.audio;
      buddyData.verifiedAudio = verifiedAudio.data.audio;
      buddyData.keywords = keywords.data.corpus;
      buddyData.pendingCorpus = pendingCorpus.data.corpus;
      
      console.debug("api -> staticupdateBuddy -> pendingCorpus", JSON.stringify(buddyData))
      const url = store.getters.getSpeakerEngines["My"].url + "updateBuddy?uid=" + store.getters.getSpeaker.uid;
      axios.post(
        url,
        buddyData
      ).then(onSuccess)
        .catch(onError);
    } catch (err) {
      console.log("Update buddy failed " + err);
      if (onError) {
        if (err instanceof Error) {
          onError(err);
        }
      } 
    }
  };

  static updateVerified = (verified: any, onSuccess: () => void, onError: (err: Error) => void) => {
    try {
      const url = store.getters.getSpeakerEngines["My"].url + "my?uid=" + store.getters.getSpeaker.uid;
      axios.post(
        url,
        verified,
      ).then(onSuccess)
        .catch(onError);
    } catch (err) {
      if (err instanceof Error) {
        onError(err);
      }
    }
  };

  static getSysLMs = (onSuccess: (data: any) => void, onError: (err: Error) => void) => {
    if (store.getters.getSpeakerEngines["My"].url) {
      const url = store.getters.getSpeakerEngines["My"].url + "lm";
      try {
        get(
          url, true
        ).then(res => onSuccess(res.data))
          .catch(onError);
      } catch (err) {
        if (err instanceof Error) {
          onError(err);
        }
      }
    } else {
      onError(new Error("empty URL, so not fetching LM"));
    }
  };

    static getUserModel = (onSuccess: (data: any) => void, onError: (err: Error) => void) => {
    if (store.getters.getSpeakerEngines["My"].url) {
      const url =
        store.getters.getSpeakerEngines["My"].url +
        "latestModel?uid=" +
        store.getters.getSpeaker.uid;
      try {
        get(
          url, true
        ).then(res => onSuccess(res.data))
          .catch(onError);
      } catch (err) {
        if (err instanceof Error) {
          onError(err);
        }
      }
    } else {
      onError(new Error("empty URL, so not fetching LM"));
    }
  };

  static getTbanks = (onSuccess: (data:any) => void, onError: (err: Error) => void) => {
    try {
      get(
        "/api/tbank/public"
      ).then(res => onSuccess(res.data))
        .catch(onError);
    } catch (err) {
      if (err instanceof Error) {
        onError(err);
      }
    }
  };

  static getTbank = (id: string, onSuccess: (data: any) => void, onError: (err: Error) => void) => {
    try {
      get(
        "/api/tbank/"+ id
      ).then(res => onSuccess(res.data))
        .catch(onError);
    } catch (err) {
      if (err instanceof Error) {
        onError(err);
      }
    }
  };

  static updateTbankPublic = (onSuccess: () => void, onError: (err: Error) => void) => {
    try {
      post(
        "/api/tbank/public",
        null
      ).then(onSuccess)
        .catch(onError);
    } catch (err) {
      if (err instanceof Error) {
        onError(err);
      }
    }
  };




  static updateKeywords = (keywords: any, onSuccess: () => void, onError: (err: Error) => void) => {
    try {
      const url = store.getters.getSpeakerEngines["Keywords"].url + "keywords?uid=" + store.getters.getSpeaker.uid;

      axios.post(
        url,
        keywords
      ).then(onSuccess)
        .catch(onError);
    } catch (err) {
      if (err instanceof Error) {
        onError(err);
      }
    }
  };

  static getSpeakers = async function () {
    let me = store.getters.getUser;
    let speakers = await get('/api/myusers');
    if (_.findIndex(speakers.data, { uid: me.uid }) < 0) {
      speakers.data.push(me);
    }
    let mySpeakers: string[] = [];
    speakers.data.forEach((speaker:any) => {
      store.dispatch("setPublicInfo", speaker);
      mySpeakers.push(speaker.uid);
      store.dispatch("updateMySpeakers", mySpeakers);
    });
    store.dispatch('updateSpeaker');

    return speakers;
  };
  
  static setSpeaker = function (uid:string) {
    post('/api/user', {
      speaker: uid
    }).then(() => {
      let user = store.getters.getUser;
      user.speaker = uid;
      store.dispatch('setUser', user);
      store.dispatch('clearLastChats');

      api.refreshSpeaker();
    }).catch ( () => {
      console.error("Failed to set Speaker")
    });
  };

  static setEngines = function (engines: any) {
    post('/api/user/' + store.getters.getUser.speaker, {
      engines: engines
    }).then(() => {
      api.refreshSpeaker();
    }).catch(() => {
      console.error("Failed to set Engines")
    });
  };
  
  static setBuddyVoice = function (voice:string) {
    post('/api/user/' + store.getters.getUser.speaker, {
      buddyVoice: voice
    }).then( () => {
      api.refreshSpeaker();
    }).catch ( () => {
      console.error("Failed to set Buddy Voice")
    });
  };

  static setAssistant = function (assistant:string) {
    post('/api/user/' + store.getters.getUser.speaker, {
      assistant: assistant
    }).then( () => {
      api.refreshSpeaker();
    }).catch ( () => {
      console.error("Failed to set Assistant")
    });
  };

  static setVadMaxActivity = function (vadMaxActivity: number) {
    post('/api/user/' + store.getters.getUser.speaker, {
      vadMaxActivity: vadMaxActivity
    }).then(() => {
      api.refreshSpeaker();
    }).catch(() => {
      console.error("Failed to set Speaker setVadMaxActivity")
    });
  };

  static setVadMaxInactivity = function (vadMaxInactivity: number) {
    post('/api/user/' + store.getters.getUser.speaker, {
      vadMaxInactivity: vadMaxInactivity
    }).then(() => {
      api.refreshSpeaker();
    }).catch(() => {
      console.error("Failed to set Speaker setVadMaxInactivity")
    });
  };

  static toggleGoogleSTT = function (value:boolean) {
    post('/api/user/' + store.getters.getUser.speaker,  {
      googleSTT : value
    }).then( () => {
      api.refreshSpeaker();
    }).catch ( () => {
      console.error("Failed to set Speaker")
    });
  }
  static toggleihearuSTT = function () {
    post('/api/user',  {
      ihearuSTT_enabled : store.getters.getUser.ihearuSTT_enabled
    }).then( () => {
      api.refreshSpeaker();
    }).catch ( () => {
      console.error("Failed to set Speaker")
    });
  }

  static toggleihearuCustomSTT = function () {
    post('/api/user',  {
      ihearuSTT_custom_enabled : store.getters.getUser.ihearuSTT_custom_enabled
    }).then( () => {
      api.refreshSpeaker();
    }).catch ( () => {
      console.error("Failed to set Speaker")
    });
  }

  static setihearuSTT_url = function (url: string) {
    post('/api/user',  {
      ihearuSTT_url : url
    }).then( () => {
      api.refreshSpeaker();
    }).catch ( () => {
      console.error("Failed to set Speaker")
    });
  }

  static setihearuSTT_custom_url = function (url: string) {
    post('/api/user',  {
      ihearuSTT_custom_url : url
    }).then( () => {
      api.refreshSpeaker();
    }).catch ( () => {
      console.error("Failed to set Speaker")
    });
  }

  static setAdmin = function (email:string, success: () => void, err: () => void) {
    post('/api/user', {
      admin: email
    }).then( () => {
      if (store.getters.getUser.admin !==email ) {
        api.getUser();
        success();
      }
    }).catch ( () => {
      console.error("Failed to set Speaker");
      err();
    });
  };

  static getSpeakerCorpus = (favourite: boolean, next: boolean) => {
    if (favourite) {
      return get('/api/corpus?state=favourite&uid=' + store.getters.getUser.speaker)
    } else {
      if (next) {
        return get('/api/corpus?count=1&state=pending&uid=' + store.getters.getUser.speaker)
      } else {
        return get('/api/corpus?state=pending&uid=' + store.getters.getUser.speaker)
      }  
    }
  };


  static deleteCorpus = (id: string, success: () => void) => {
    return HttpDelete('/api/corpus/' + id).then(() => {
      api.refreshSpeaker();
      success();
    });
  };

  static deleteAllCorpus = (state: string, success: () => void) => {
    return HttpDelete('/api/corpus?state=' + state + '&uid=' + store.getters.getUser.speaker).then(() => {
      api.refreshSpeaker();
      success();
    });
  };

  static addCorpus = function (text: string[], favourite: boolean, success: () => void, onError: (err: Error) => void) {
    post('/api/corpus/' + store.getters.getUser.speaker + ((favourite) ? "?favourite=true" : ""), {
      text: text,
      lm: store.getters.getSelectedLM
    }).then((res) => {
      api.refreshSpeaker();
      api.updateBuddyTrainLm(success, onError)
      console.debug("Corpus added");
    }).catch ( (err) => {
      console.error("Failed to set SPeaker");
      onError(err);
    });
  };
  static deleteContact = function (contact: string, success: () => void) {
    HttpDelete('/api/user/' + store.getters.getUser.uid + "/contacts/" + contact).then((res) => {
      api.getUser();
      console.debug("Contact LM deleted " + res);
      success && success();
    }).catch((err) => {
      console.error("Failed to set lm " + err);
    });
  };

  static addLM = function (lmName: string, lmText: string, lmType: string, success: () => void) {
    post('/api/user/' + store.getters.getUser.speaker + "/lm/" + lmName, {
      unique: Math.random(),
      text: lmText,
      type: lmType
    }).then((res) => {
      api.refreshSpeaker();
      console.debug("LM added");
      success();
    }).catch((err) => {
      console.error("Failed to add lm", err)
    });
  };

  static deleteLM = function (lm: any, success: () => void) {
    HttpDelete('/api/user/' + store.getters.getUser.speaker + "/lm/" + lm.id).then((res) => {
      api.refreshSpeaker();
      console.debug("LM deleted");
      api.updateBuddyDeleteLm(lm.name, success, () => { throw new Error("update buddy for Delete LM failed") });
      // success();
    }).catch((err) => {
      console.error("Failed to set lm " + err);
    });
  };

  static getLMbyId = function (id: string, success: (res:any) => void) {
    get('/api/user/' + store.getters.getUser.speaker + "/lm/" + id).then((res) => {
      // api.refreshSpeaker();
      console.debug("LM added");
      success(res.data);
    }).catch(() => {
      console.error("Failed to add lm")
    });
  };

  static setLM = function (lm: any, success: () => void) {
    put('/api/user/' + store.getters.getUser.speaker + "/lm/" + lm.id, lm).then((res) => {
      api.refreshSpeaker();
      console.debug("LM edited");
      success();
    }).catch((err) => {
      console.error("Failed to set lm " + err);
    });
  };
  
  static setDefaultLM = function (name: string, success: () => void) {
    api.setLM( { id: "default", name: name, state: "default" }, success);
    store.dispatch("setMyLastChatLM", { token: store.getters.chatToken, myLastLM: name || ""});
  };

  static createChat = function (title: string, success: Function) {
    post('/api/chat',
      {
        speaker: store.getters.getUser.speaker,
        title: title
      }).then((res) => {
      // api.refreshSpeaker();
      // console.debug("Chat added" + JSON.stringify(res));
        api.getChats(() => { });
        success(res.data);
    }).catch(() => {
      console.error("Failed to add chat")
    });
  };

  static setChatTitle = function (token:string, title: string, success: () => void, err: () => void) {
    post('/api/chat/'+token, {
      title: title
    }).then(() => {
        success();
    }).catch(() => {
      console.error("Failed to set chat title");
      err();
    });
  };

  static getChats = function (success: Function) {
    get('/api/chat?speaker=' + store.getters.getUser.speaker).then((res) => {
      success(res.data);
    }).catch((err) => {
      console.error("Failed to get chat",err)
    });
  };
  
  static getChat = function (token: string, success: Function) {
    get('/api/chat/' + token).then((res) => {
      success(res.data);
      console.log("Get chat for token: " + token);
    }).catch(() => {
      console.error("Failed to get chat")
    });
  };

  static joinChat = function (token: string, success: Function) {
    let speaker = store.getters.getUser.speaker || store.getters.getUser.uid;
    post('/api/chat/' + token + "/join/" + speaker , {}).then((res) => {
      success(res.data);
      console.log("Joined chat for token: " + token);
    }).catch(() => {
      console.error("Failed to get chat")
    });
  };

  static removeChat = function (uid: string, token: string, success: () => void) {
    HttpDelete('/api/chat/' + token + "/remove/" + uid).then((res) => {
      console.log(uid + " removed from Chat " + token);
      success();
    }).catch((err) => {
      console.error("Failed to removeChat",err)
    });
  };

  static shareChat = function (email: string, token: string, origin: string) {
    post('/api/mail',
      {
        speaker: store.getters.getUser.speaker,
        email: email,
        token: token,
        origin: origin
      }).then((res) => {
        api.getUser();
        console.log("Shared by email", res.data);
      }).catch(() => {
        console.error("Failed to share by email")
      });
  };

  static proxy = function (url: string, text: string) {
    post('/api/proxy',
      {
        url: url,
        text: text
      }).then((res) => {
        console.log("proxy response", res.data);
      }).catch(() => {
        console.error("Failed to proxy")
      });
  };
  static logAnalytics = function (name: string, params: any) {
    logEvent(analytics, name, params);
  }

}