import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Auth from '../utils/auth'
import store from '@/store'
import helper from '@/utils/helpers'

Vue.use(VueRouter)
let isFirstTransition = true;

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/train',
    name: 'train',
    component: () => import('../views/Run.vue')
  },
  {
    path: '/qtrain',
    name: 'qtrain',
    component: () => import('../views/QTrain.vue')
  },
  {
    path: '/speak',
    name: 'speak',
    component: () => import('../views/Speak.vue')
  },
  {
    path: '/assistants',
    name: 'assistants',
    component: () => import('../views/Assistants.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/Chat.vue')
  },
  {
    path: '/keywords',
    name: 'keywords',
    component: () => import('../views/Keywords.vue')
  },
  {
    path: '/assignment',
    name: 'assignment',
    component: () => import('../views/ManageCorpus.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('../views/Verify.vue')
  },
  {
    path: '/analysis',
    name: 'analysis',
    component: () => import('../views/Analysis.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/Account.vue')
  },
  {
    path: '/videocc',
    name: 'videocc',
    component: () => import('../views/VideoCC.vue')
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('../views/SendFeedback.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/Help.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach(to => {
  localStorage.setItem("aihearu-router", to.fullPath);
  if (to.path === "/speak") {
    const tab = (new URL("https://aihearu.com" + to.fullPath)).searchParams.get("tab");
    if (tab) {
      localStorage.setItem("aihearu-router-speak-tab", tab);
    }
  }
});

router.beforeEach((to, from, next) => {
  // checkForUpdates()
  // const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  // const isTokenSet = store.getters.isTokenSet
  // if (requiresAuth && !isTokenSet) {
  //   return next('/login')
  // }
  // checkIfTokenNeedsRefresh()
  // store.commit(types.SUCCESS, null)
  // store.commit(types.ERROR, null)
  // return next()


  if (store.getters.getRecording) {
    if (to.name !== from.name) {
      store.dispatch("setRecording", false);
      console.log("Recording was enabled when navigation triggered");
    }
  }

  if (isFirstTransition) {
    isFirstTransition = false;
    if ((to.path === '/') || (to.path === '')) {
      const prev = localStorage.getItem('aihearu-router') || '';
      if (prev.length > 0) {
        return next(prev);
      }
    }
  }
  if (to.path ==='/login') { 
    next();
  } else {
    try {
      if (to.path.startsWith('/chat')) {
        if (to.query.token) {
          store.dispatch("setRedirect", to.fullPath)
        }
      }
    } catch (err) {
      console.error("found /chat but could not find token", err)     
    }
    Auth.checkAuth(
      () => {
        return next();
      },
      () => {
        console.log("checkAuth failed so redirecting to login page");
        return next('/login');
    });
  }
});

export default router
