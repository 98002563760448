<template>
  <v-container>
      <v-progress-linear dark indeterminate color="secondary" height="20" striped>{{text}}</v-progress-linear>
  </v-container>

</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: "Loading",
  props: {
    onLoaded: Function,
    text: {
      default: "...loading",
      type: String
    }
  },
  computed: {
    ...mapGetters({speaker: "getSpeaker"})
  },
  mounted() {
    if (this.speaker.uid !== 'self') {
      this.onLoaded();
      console.log("already loaded");
    }
  },
  watch: {
    'speaker'(value) {
      if (value) {
        this.onLoaded();
        console.log("Speaker loaded");
      } 
    }, 
  }
}
</script>
