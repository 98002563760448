<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen>
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="onComplete" outlined raised elevation="2">ok</v-btn>
        </v-card-actions>
        <v-card-text><pre>{{text}}</pre></v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
      props: {
        text: String,
        onComplete: Function
      },
      watch: {
        'dialog'(to) {
          if (!to) {
            this.onComplete();
          }     
        }
      },
    data () {
      return {
        dialog: true
      }
    },
  }
</script>