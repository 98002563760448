<template>
  <div class="dashboard">
      <v-container class="md12 grid-list-md">
            <v-layout wrap>
              <v-flex v-bind:key="stat.label" v-for="stat in stats" class="xs6 md3" align="center" justify="center">
                <v-card  align="center" justify="center" @click="clicked(stat)" height="250px" >
                  <v-responsive v-if="stat.msg" fill-height fluid>
                      <h1   class ="ma-1" align="center" justify="center">{{stat.msg}}</h1>
                      <v-container><v-btn v-if="stat.icon" fab dark width="150" height="150" elevation="20" bottom :color="stat.color">
                        <v-icon  size="100">{{stat.icon}}</v-icon>
                      </v-btn> 
                      </v-container>
                    <v-spacer/>
                  </v-responsive>
                  <v-responsive v-if="!stat.msg" :aspect-ratio="1" fill-height fluid class="x-ctn">
                  <h1   class ="ma-3" align="center" justify="center">{{stat.label}}</h1>
                    <v-progress-circular
                      
                      :size="150"
                      :width="20"
                      :value="stat.percent"
                      :color="stat.color || 'primary'"
                      class ="ma-3"
                    >
                      <h1>{{stat.number}}</h1>
                    </v-progress-circular>
                  </v-responsive>
                  <!-- <v-alert color="primary"  raised elevation="3" colored-border >{{stat.info}}</v-alert> -->
                </v-card>
              </v-flex>
            </v-layout>

      </v-container>

  </div>
</template>

<script>

export default {
  name: 'Dashboard',
  props: {
    stats: Array,
    tasks: Array 
  },
  data () {
    return {
      users: []
    }
  },
  methods: {
    clicked(stat) {
      console.log("🚀 ~ file: Dashboard.vue ~ line 52 ~ clicked ~ stat", stat.label);
      stat.cb()

    },
    ping: function () {
      axios.get('http://localhost:5001/ihearu-dev/us-central1/app/ping')
      .then((result) => {
        console.log(result.data);
      })
      .catch((err) => {
        console.log("Error", err);
      });
    }
  }
}
</script>