import axios from 'axios'
import store from '../store'

export default class ashttp {
    static get = (url = '') => {
        return axios.get(
            url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getters.getTokenId,
            }
        });
    };

    static post = (url = '', data: any, headers: any = {}) => {
        return axios.post(
            url, 
            data, {
                headers: {
                    ...headers,
                    ...{
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + store.getters.getTokenId,
                    }
                }
        });
    }

    static put = (url = '', data:any ) => {
        return axios.put(
            url, 
            data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getters.getTokenId,
            }
        });
    }

    static delete = (url = '') => {
        return axios.delete(
            url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getters.getTokenId,
            }
        });
    };
}