<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="500">
      <v-card >
        <v-toolbar dark>
          <v-btn @click="onCancel" elevation="0" >
            <v-icon >mdi-window-close</v-icon>
          </v-btn>
          <v-toolbar-title  dark>{{title}}</v-toolbar-title>
        </v-toolbar>
        <v-card-actions v-if="onComplete">
          <v-text-field
          v-model="modifiedText"
          :label="title"
          @keydown.enter="textChanged"
          ></v-text-field>
          <v-btn color="success" icon @click="textChanged"><v-icon>mdi-check</v-icon></v-btn>
        </v-card-actions>
        <v-card-actions> 
          <v-btn v-if="leftButton" :color="leftButton.color" @click="leftButton.onClick" outlined>
            {{leftButton.label}}
            <v-icon v-if="leftButton.icon">{{leftButton.icon}}</v-icon>
          </v-btn>         
          <v-spacer></v-spacer>
          <v-btn v-if="rightButton" :color="rightButton.color" @click="rightButton.onClick" outlined>
            {{rightButton.label}}
            <v-icon v-if="rightButton.icon">{{rightButton.icon}}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
      props: {
        title: String,
        text: String,
        onCancel: Function,
        onComplete: Function,
        rightButton: {
          default: null,
          type: Object
        },
        leftButton: {
          default: null,
          type: Object
        }
      },
      watch: {
        'dialog'(to) {
          if (!to) {
            this.textChanged();
          }     
        }
      },
      mounted() {
        this.modifiedText = this.text;
      },
    data () {
      return {
        dialog: true,
        modifiedText: ""
      }
    },
    methods: {
      textChanged() {
        if (this.onComplete) {
          this.onComplete(this.modifiedText);
        }
      }
    }
  }
</script>