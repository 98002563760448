<template>
  <div class="text-center">
    <v-menu>
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              dark
              outlined
              v-on="{ ...tooltip, ...menu }"
              @click="fetchSpeakers"
            >{{speaker.displayName}}</v-btn>
          </template>
          <span>Current Speaker</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in speakers"
          :key="index"
          @click="updateSpeaker(item)"
        >
          <v-list-item-title>{{ item.displayName }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import api from '../utils/api'
  export default {
    name: "SpeakerMenu",
    data: () => ({
      items: [],
      speakers: []
    }),
    computed : {
      speaker() {
        const sp = this.$store.getters.getSpeaker;
        return sp;
      }, 
        },
    methods: {
      getSpeakers() {
          console.log("getSpeakers -> getSpeakers")
          this.items = [
              { title: 'Click Me1' },
              { title: 'Click Me2' },
              { title: 'Click Me3' },
              { title: 'Click Me4' },
          ]
      },
      fetchSpeakers () {
        let vm = this;
        api.getSpeakers()
        .then( (result) => {
          console.log(" Got speakers: " + result.data);
          vm.speakers = result.data;
        })
        .catch((err) => {
          console.log("Error", err);
        });
      },
      updateSpeaker (speaker) {
        console.log("Speaker : " + speaker);
        api.setSpeaker(speaker.uid);
      },
    }
  }
</script>