<template>
  <div class="home" >

    <Loading v-if="!appReady" :onLoaded="onLoaded"/>
    <div v-if="appReady">
    <v-alert  color="primary" class="ma-2" raised elevation="3" colored-border prominent outlined>
      <h3 class="headline" align="center">Speaker: "{{speaker.displayName}}"</h3>
    </v-alert>

    <h1> </h1>
    <Dashboard :stats="stats" :tasks="tasks" />
    <v-divider></v-divider>

    <v-container fluid>
      <v-sparkline
      class="ma-5"
        ref="graph"
        :value="uploadStats.tenDays"
        :gradient="gradient"
        :smooth="radius || false"
        :padding="padding"
        :line-width="lineWidth"
        :stroke-linecap="lineCap"
        :gradient-direction="gradientDirection"
        :fill="fill"
        :type="type"
        :auto-line-width="autoLineWidth"
        auto-draw
        :show-labels="showLabels"
        :label-size="labelSize"
        :labels="labels"
      ></v-sparkline>
    <h2 align="center">Upload trend in the last 10 days</h2>

    <v-divider></v-divider>

  </v-container>
  </div>
 <ResponsiveWave/>
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors';
import ResponsiveWave from "../components/ResponsiveWave";
import Dashboard from "../views/Dashboard.vue"
import helpers from "../utils/helpers"
import Loading from '../components/Loading'

  const gradients = [
    ['#222'],
    ['#42b3f4'],
    ['red', 'orange', 'yellow'],
    [colors.blue.darken1, "black"],
    ['#00c6ff', '#F0F', '#FF0'],
    ['#f72047', '#ffd200', '#1feaea'],
  ]

  export default {
    name: "Home",
    components: {ResponsiveWave, Dashboard, Loading},
    mounted() {
      let vm = this;
      this.initStatCBs();
    },
    watch: {
      'speaker'(value) {
        this.updateStats(); 
      }, 
    },
    methods: {
      goto(url) { helpers.goto(url); },
      gotoVerify() { this.goto('/verify')},
      gotoQTrain() { this.goto('/qtrain')},
      gotoAssignment() { this.goto('/assignment')},
      gotoAssistant() { this.goto('/assistants')},
      gotoSpeak() { this.goto('/speak')},
      onLoaded() {
        this.updateStats();
        this.appReady = true;
      },
      initStatCBs() {
        this.stats[0].cb = this.gotoQTrain;
        this.stats[1].cb = this.gotoQTrain;
        this.stats[2].cb = this.gotoSpeak;
        this.stats[3].cb = this.gotoAssistant;
      },
      updateStats() {
          if (this.speaker.stats && this.speaker.stats.corpus.count > 0) {
            this.stats[0].label = 'To train';
            this.stats[0].color = 'primary';
            this.stats[0].cb = this.gotoQTrain;
            this.stats[0].msg = "";
            this.stats[0].number = this.speaker.stats.corpus.count
          } else {
            this.stats[0].msg = 'Click to add training phrases';
            this.stats[0].color = 'error';
            this.stats[0].cb = this.gotoAssignment;
          }

          if (this.speaker.stats &&  this.speaker.stats.audio.count) { // has uploaded before
            this.stats[1].number = this.speaker.stats.audio.count;
            this.stats[1].percent = this.speaker.stats.audio.count;
            this.stats[1].msg = "";
            this.stats[1].color = 'primary';
            this.stats[1].cb = this.gotoQTrain;
          } else if (this.speaker.stats && this.speaker.stats.corpus.count) { // not uploaded but has pending corpus
            this.stats[1].msg = 'Ready to train?';
            this.stats[1].cb = this.gotoQTrain;
          } else {  // not uploaded and no pending corpus
            this.stats[1].msg = 'No training phrases found';
            this.stats[1].color = 'error';
            this.stats[1].cb = this.gotoAssignment;
          }

          // if (this.uploadStats.streak) {
          //   this.stats[3].msg = '';
          //   this.stats[3].number = this.uploadStats.streak;
          //   this.stats[3].percent = this.uploadStats.streak * 10;
          //   this.stats[3].cb = this.gotoQTrain;   

          // } else {
          //   this.stats[3].msg = 'No uploads today';
          //   this.stats[3].color = 'primary';
          //   this.stats[3].cb = this.gotoQTrain;   
          // }
            this.stats[3].msg = 'Assistant';
            this.stats[3].cb = this.gotoAssistant;
            if (!this.speaker.assistant  || this.speaker.assistant === "None") {
              this.stats[3].color = 'accent';
              this.stats[3].icon = "mdi-cog-refresh-outline";
            } else {
              this.stats[3].color = 'primary';
              this.stats[3].icon = "mdi-home-assistant";

            }

          // LM
          let lm = this.lm();
          if (lm.count) {
            if (!lm.hasKeywords) {
              this.stats[2].msg = "Create keywords vocabulary";

            } else {
              this.stats[2].number = lm.count;
              this.stats[2].percent = lm.count * 20;
              this.stats[2].msg = "";
            }
          } else {
            this.stats[2].msg = "Create custom vocabulary";
          }
          this.stats[2].cb = this.gotoSpeak;   
      },

      lm() {
        let result = {};
        result.count = 0;
        for (const lm of _.keys(this.speaker.lm)) {
          if (this.speaker.lm[lm].name == "Keywords") result.hasKeywords = true;
          if (this.speaker.lm[lm].id !== "default") result.count++;
        }
        return result;
      },
   },
    computed: {

      uploadStats() {
        let daysToday = helpers.daysIntoYear();
        let usage= {
          tenDays: [0,0,0,0,0,0,0,0,0,0,0],
          streak: 0
        }
        try {
          const userStats = this.$store.getters.getSpeaker.stats.audio;
          usage.tenDays.forEach((day, index) => {
            usage.tenDays[index] = userStats[(daysToday - 10 + index).toString()] | 0;
          });
          for(let i=0; i < 366; i++) {
            if (userStats[(daysToday - i).toString()] > 0) {
              usage.streak++;
            } else {
              break;
            }
          }
        } catch (err) {
          console.debug(err);
        }
        return usage;
      },
      totalUploads() {
        try {
          return this.$store.getters.getSpeaker.stats.audio.count;
        } catch (err) {
          return 0;
        }      
      },
      user() {
        return this.$store.getters.getUser;
      },
      speaker() {
        return this.$store.getters.getSpeaker;
      }
    },

    data: () => ({
      appReady: false,
      showLabels: true,
      labels: ["10","9", "8", "7", "6", "5", "4", "3", "2", "1", "today."],
      lineWidth: 2,
      labelSize: 7,
      radius: 10,
      padding: 8,
      lineCap: 'round',
      gradient: [],
      gradientDirection: 'top',
      fill: false,
      type: 'trend',
      autoLineWidth: false,
      showLM: false,
      stats: [
        {
          number: '0',
          label: 'To train',
          percent: 5,
          info: "This is info",
        },
        {
          number: '0',
          label: 'Uploaded',
          percent: 40,
          info: "This is info",
        },
        {
          number: '95',
          label: 'Vocabulary',
          percent: 10,
          info: "This is info",
        },
        {
          number: '0',
          label: 'Streak',
          percent: 100,
          info: "This is info",
          msg: "No uploads today",
        },
      ],
      tasks: [
        {
          id: 0,
          title: 'Book meeting for Thursday'
        },
        {
          id: 1,
          title: 'Review new leads'
        },
        {
          id: 2,
          title: 'Be awesome!'
        },
      ]
    }),
  }
</script>
