<template>
  <v-app id="inspire" 
  styletbd="
    background: grey;
    background: -webkit-radial-gradient(#FBFBFF,  #2c3e50, #FBFBFF);
    background: radial-gradient(  #FBFBFF, #dad6ff);
  "
  >
    <v-snackbar top :value="promptUpdate" :timeout="-1" color="warning">
      An update is available
      <v-btn class="mx-3" outlined @click="refreshApp">
        Update now
      </v-btn>
    </v-snackbar>
    <v-app-bar v-if="!isAnon" :clipped-left="$vuetify.breakpoint.lgAndUp" app color="topbar" dark height="60">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4" @click="goto('/')" link>
        <span>{{routeName}}</span>
      </v-toolbar-title>
      <!-- v-text-field flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" label="Search" class="hidden-sm-and-down"/>
      <v-spacer />
      <v-btn icon>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn-->
      <v-spacer />
      <!-- <v-btn @click="refreshSpeaker" icon>
        <v-icon >mdi-refresh</v-icon>
      </v-btn> -->
      <!-- <Notifications/> -->
      <!-- <v-btn v-if="user.dev" @click="goto('/analysis')" icon>
        <v-icon>mdi-brain</v-icon>
      </v-btn> -->

    <SpeakerMenu v-if="showNav"/>
      <v-btn v-if="showNav" icon large @click="goto('/account')" raised >
        <v-avatar size="32px" item>
          <v-img :src="speaker.avatar" alt="avatar"/>
        </v-avatar>
      </v-btn>
    </v-app-bar>
    <v-app-bar v-if="isAnon" :clipped-left="$vuetify.breakpoint.lgAndUp" app color="topbar" dark height="60">
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4" @click="showEditAnonName = true">
        <span> Guest ~ {{user.displayName}}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn outlined @click="logout">Leave</v-btn>
      <ModalTextEdit
        v-if="showEditAnonName"
        :onCancel="cancelShowEditAnonName"
        :onComplete="onEditAnonName"
        title="Your display name"
        :text="user.displayName"
      />
    </v-app-bar>
    <v-navigation-drawer 
      v-if="showNav" 
      width="250"
      app 
      v-model="drawer" 
      :clipped="$vuetify.breakpoint.lgAndUp" 
      color="sidebar" 
      :expand-on-hover="false && $vuetify.breakpoint.md" 
      :permanent="isLarge"
    >
    <EasyRefresh
      :userSelect="false"
      :onRefresh="refreshApp"
      > 
        <template v-slot:header>
          <ClassicsHeader
            refreshText="Pull to refresh"
            refreshReadyText="Ready to refresh"
            refreshingText="Refreshing"
            refreshedText="Refreshed"
            moreInfo=""
            :showMore="true"/>
        </template>
<v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <div v-else :key="item.text">
            <v-hover v-slot="{ hover }">
              <v-list-item  v-if="menuEnabled(item)" link @click="goto(item.url)">
                <v-list-item-action>
                  <v-icon :color="hover ? 'primary' : ''">{{item.icon}} </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title >
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-hover>
          </div>
          
        </template>
                      <v-list-item link @click="goto('help')">
                <v-list-item-action>
                  <v-icon>$vuetify.icons.buddyIcon</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title >
                    About
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
        </v-list>
    </EasyRefresh>
      <template v-slot:append>
        <v-list>
                    
          <v-list-item link @click="goto('/account')">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.displayName }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-copyright</v-icon>
            </v-list-item-action>        
            <v-list-item-content><v-list-item-title class="font-weight-thin" @click="showChangeLog = true">{{getServer.name}} ({{version}})</v-list-item-title></v-list-item-content>
            
          </v-list-item>
        </v-list>
        <ModalText v-if="showChangeLog" :onComplete="doneShowChangeLog" :text="changeLog()"/>
      </template>
    </v-navigation-drawer>


    <v-main>
      <router-view></router-view>      
    </v-main>
        <v-btn v-if="showFab" :elevation="fab.elevation" bottom :color="fab.color" x-large dark fab fixed right @click="fabClicked" v-on:click.ctrl="fabClicked" >
      <v-avatar size="42px" tile>
        <img v-if="fab.img" src="./assets/ihearu/iHearuLogo-white.png" />
        <v-icon v-if="fab.icon.length > 0" >{{fab.icon}}</v-icon>
      </v-avatar>
    </v-btn>

    <v-dialog v-model="dialog" width="800px"></v-dialog>
    <v-footer height="56px" padless color="sidebar">
      <v-col
        class="text-center grey--text"
        cols="12"
        id="foo"
         @contextmenu="openSponsor()"
      >
        Supported by — <strong> {{sponsor.name}}</strong>
      </v-col>
    </v-footer>      
  </v-app>
</template>

<script>
import helpers from './utils/helpers';
import api from './utils/api';
import SpeakerMenu from "./components/SpeakerMenu";
import ModalText from "./components/ModalText";
import ModalTextEdit from "./components/ModalTextEdit";
import mdFile from 'raw-loader!../Changelog.md'
import mdFileProd from 'raw-loader!../ChangelogProd.md'
import Auth from './utils/auth';
// import Notifications from './components/Notifications.vue';
import { mapGetters } from 'vuex';

export default {
    props: {
      source: String,
    },
    components: {SpeakerMenu, ModalText, ModalTextEdit}, // Notifications},
    created() {
      document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    },
    watch: {
      '$store.state.server'(val) {
        this.version = this.getVersion();
        if (val.sponsor) {
          this.sponsor = _.sample(val.sponsor);
        } 
      },
      'user'(){
        if (this.showRecording) {
          this.showFab = (this.user.anon && this.$device.mobile) ? false : true;
        }
      },
      'showRecording'(val){
        if (val ) {
          this.showFab = (this.user.anon && this.$device.mobile) ? false : true;
        } else {
          this.showFab = false;
        }
      },
      'recording'(value) {
        if (value) {
          this.fab = { color: "warning", elevation: "20", img:false, icon:"mdi-stop" };
          this.fabTimerStart();
        } else {
          this.timer = -1;
          this.fab = { color: "primary", elevation: "0", img: false, icon: "mdi-microphone"};
        } 
      }
    },
    mounted() {
      let vm = this;
      vm.showFab = false;
      vm.version = vm.getVersion();
      api.getServer();
      this.$store.dispatch("setVersion", { app: vm.version });
      // window.addEventListener('keydown', function(e) {
      //   if ((e.target.tagName === "BODY") && (e.code === "Space")) {
      //     vm.fabClicked();
      //     e.preventDefault();
      //     console.log("fabClicked by Spacebar");
      //   }
      // });
      window.speechSynthesis.getVoices();
      api.logAnalytics('screen_view', {
        firebase_screen: "App Loaded", 
        firebase_screen_class: "App.vue",
        app_version: vm.version
      });
      api.logAnalytics('notification_received', {
        app_version: vm.version
      });
    },
    computed : {
      // theme(){
      //   console.log("theme -> this.$vuetify.theme", this.$vuetify.theme)
      //   return (this.$vuetify.theme.dark) ? 'dark' : 'light'
      // },
      ...mapGetters({
        recording: 'getRecording',
        speaker: 'getSpeaker',
        user: 'getUser',
        showRecording: 'getShowRecording'
      }),

      isAnon(){
        return (this.user.anon && this.user.uid !=="self")
      },
      showNav(){
        return (!this.user.anon && this.user.uid !=="self")
      },
      routeName() {
        let rName = this.$route.name;
        let res = "AIHEARU";
        this.items.forEach(item => {
          if (rName === item.url) {
            res = item.text;
          }
        });
        return res;
      },
      // fab() {
      //   if (this.recording) {
      //     return { color: "warning", elevation: "20", img:false, icon:"mdi-stop" }
      //   }
      //   return { color: "primary", elevation: "0", img: false, icon: "mdi-microphone"};
      // },
      getServer() {
        return this.$store.getters.getServer;
      },
      isLarge() {
        return this.$vuetify.breakpoint.name !== 'xs'
      },


    },
    methods: {
      menuEnabled(item){
        return (!item.advanced || this.user.advanced)
      },
      updateAvailable(event) {
        this.promptUpdate = true;
      },
      changeLog() {
        return (this.getServer.name.match("dev")) ? mdFile.toString() : mdFileProd.toString();
      },
      goto(url) { helpers.goto(url); },
      getVersion() { 
        const regex = /` [0-9.]+/g
        const found = this.changeLog().match(regex);
        return found[0].substr(2);
      },
      fabTimerStart() {
        let vm = this;
        vm.timer = 20;
        vm.rpt();        
      },
      rpt() {
        let vm = this;
        setTimeout(() => {
          vm.timer--;
          if (vm.timer < 10) {
            vm.fab.icon = "mdi-numeric-" + vm.timer;
          } else {
            vm.fab.icon = 'mdi-stop';
          }
          if (vm.timer > 0 ) {
            vm.rpt();
          } else {
            vm.fab.icon = 'mdi-timelapse';
          }
          if (vm.timer < 0) {
            vm.fab.icon = 'mdi-microphone';
          }
        }, 1000); 
      },
      fabClicked() {
        if (
          (this.$route.path  === '/chat') ||
          (this.$route.path  === '/train') ||
          (this.$route.path  === '/qtrain') ||
          (this.$route.path  === '/speak') 
        ) {
          this.$store.dispatch("toggleRecording");
        } else {
          this.goto('/run');
        }
      }, 
      refreshSpeaker() {
        api.refreshSpeaker();
      },
      doneShowChangeLog() {
        this.showChangeLog = false;
        api.logAnalytics('screen_view', {
          firebase_screen: "Changelog", 
          firebase_screen_class: "App.vue"
        });
      },
      refreshApp() {
        window.location.reload();
      },
      openSponsor() {
        window.open(this.sponsor.url, "_blank");
        console.log("open Sponsor url " + this.sponsor.url);      
      },
      login() {
        let redirect = this.$store.getters.getRedirect;
        if (redirect === "")  redirect = "/login";
        Auth.googleLogin(redirect);
      },
      logout() {
        // let redirect = this.$store.getters.getRedirect;
        // if (redirect === "")  redirect = "/login";
        Auth.googleLogout();
      },
      cancelShowEditAnonName() {
        this.showEditAnonName = false;
      },
      onEditAnonName(name) {        
        api.setDisplayName(name, () => {
          this.cancelShowEditAnonName();
        }, (err) => {
          console.log("🚀 ~ file: App.vue ~ line 263 ~ api.setDisplayName ~ err", err)
        });
      }
    },
    data: () => ({
      dialog: false,
      drawer: null,
      items: [
        { icon: 'mdi-home', text: 'Home' , url: '/'},
        { icon: 'mdi-chat', text: 'Chat' , url: 'chat', advanced: true},
        { icon: 'mdi-account-voice', text: 'Speak' , url: 'speak'},
        { icon: 'mdi-page-next-outline', text: 'Train' , url: 'qtrain', advanced: false},
        { icon: 'mdi-microphone', text: 'Run' , url: 'train', advanced: true},
        { icon: 'mdi-home-assistant', text: 'AI Assistants' , url: 'assistants', advanced: false},
        { icon: 'mdi-video-account', text: 'Video CC' , url: 'videocc', advanced: false},
        { icon: 'mdi-weight-lifter', text: 'Assignment', url: 'assignment', advanced: true },
        { icon: 'mdi-spellcheck', text: 'Verify', url: 'verify', advanced: true },
        { icon: 'mdi-brain', text: 'Analysis', url: 'analysis', advanced: true },
        // { icon: 'mdi-email', text: 'Send feedback', url: 'feedback' },
        // { icon: "buddyIcon", text: 'Buddy', url: 'help' },
      ],
      fab: { color: "primary", elevation: "0", img: false, icon: "mdi-microphone"},
      showFab: false,
      showChangeLog: false,
      showEditAnonName: false,
      timer : 20,
      version: "0.0.0",
      server: "",
      promptUpdate: false,
      sponsor: {
        url: "https://aihearu.com",
        name: "AIHEARU"
      }
    }),
  }
</script>