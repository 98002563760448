import helpers from '@/utils/helpers'
import _ from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'
import defaults from '../utils/defaults'

Vue.use(Vuex)
const getDefaultState = () => {
  return {
    mySpeakers: [""],
    publicInfo: new Map(),
    recording: false,
    showRecording: false,
    redirect: "",
    server: { name: "AIHEARU" },
    speaker: defaults.user,
    tokenId: "",
    user: defaults.user,
    version: { app: "0.0.0" },
    chatToken: "",
    lastChats: { chats: new Map(), ts: 0 },
    currentLm: { name: "", id: "" }
  }
}

export default new Vuex.Store({
  state: getDefaultState,
  mutations: {
    setUser(state, value) {
      state.user = value;
    },
    setPublicInfo(state, user) {
      state.publicInfo.set(user.uid, user);
    },
    setTokenId(state, value) {
      state.tokenId = value;
    },
    setRecording(state, value) {
      state.recording = value;
    },
    setShowRecording(state, value) {
      if (!state.recording) {
        state.showRecording = value;
      }
    },
    setSpeaker(state, value) {
      if (state.speaker.uid !== value.uid) {
        state.currentLm = {name: "", id: ""}
      }
      if (!value.vadMaxActivity) {
        value.vadMaxActivity = defaults.user.vadMaxActivity;
      }
      if (!value.vadMaxInactivity) {
        value.vadMaxInactivity = defaults.user.vadMaxInactivity;
      }
      state.speaker = value;
    },
    setMySpeakers(state, value) {
      state.mySpeakers = value;
    },
    setVersion(state, value) {
      state.version = value;
    },
    setServer(state, value) {
      state.server = value;
    },
    reset(state) {
      Object.assign(state, getDefaultState());
    },
    setRedirect(state, value) {
      state.redirect = value;
    },
    setChatToken(state, value) {
      state.chatToken = value;
    },
    setLastChats(state, value) {
      if (value.last) {
        value.last.token = value.token;
        let current = state.lastChats.chats.get(value.token);

        // save last only if newer than current
        if (!current || current.ts < value.last.ts) {
          state.lastChats.chats.set(value.token, value.last);
        }
        state.lastChats.ts = value.last.ts;
      } else {
        state.lastChats.chats.delete(value.token);
        state.lastChats.ts = new Date().getMilliseconds();
      }
    },
    clearLastChats(state) {
      state.lastChats.chats.clear();
      state.lastChats.ts = new Date().getMilliseconds();
    },
    setLastChatNotify(state, value) {
      let chat = state.lastChats.chats.get(value.token);
      if (chat) {
        chat.notify = value.notify;
        state.lastChats.chats.set(value.token, chat);
        state.lastChats.ts = new Date().getMilliseconds();
      }
    },
    setMyLastChatLM(state, value) {
      state.speaker.lm.default = { id: "default", name: value.myLastLM, state: "inactive"};
      // state.speaker.lm.default.name = value.myLastLM;
      let chat = state.lastChats.chats.get(value.token);
      if (chat) {
        chat.myLastLM = value.myLastLM;
        state.lastChats.chats.set(value.token, chat);
        state.lastChats.ts = new Date().getMilliseconds();
      }
      let lms: any = state.speaker.lm;
      if (lms[value.myLastLM]) {
        state.currentLm = { name: lms[value.myLastLM].name, id: value.myLastLM };
      } else if (value.myLastLM.endsWith("-system")) {
        state.currentLm = { name: _.split(value.myLastLM, "-")[0], id: value.myLastLM };
      } else {
        state.currentLm = {name: "", id: ""}
      }
    },
    setCurrentLm(state, value) {
      state.currentLm = value;
    }

  },
  actions: {
    updateSpeaker(context) {
      let speaker = context.state.publicInfo.get(context.state.user.speaker) || {};
      if (Object.prototype.hasOwnProperty.call(speaker,"uid")) {
        context.commit('setSpeaker', speaker);
      }
    },
    setUser(context, value) {
      context.commit('setUser', value);
      context.dispatch("updateSpeaker");
    },
    setPublicInfo(context, user) {
      if (!user.engines) {
        user.engines = defaults.user.engines;
      }
      context.commit('setPublicInfo', user);
      context.dispatch("updateSpeaker");
    },
    setTokenId(context, tokenId) {
      context.commit('setTokenId', tokenId);
    },
    setChatToken(context, chatToken) {
      context.commit('setChatToken', chatToken);
    },
    setLastChats(context, value) {
      context.commit('setLastChats', { token: value.token, last: value.last});
    },
    setLastChatNotify(context, value) {
      if (value.token) {
        context.commit('setLastChatNotify', { token: value.token, notify: value.notify });
      }
    },
    setMyLastChatLM(context, value) {
      if (value.token) {
        context.commit('setMyLastChatLM', { token: value.token, myLastLM: value.myLastLM });
      }
    },
    clearLastChats(context) {  
      context.commit('clearLastChats');
    },
    toggleRecording(context) {
      context.commit('setRecording', !context.state.recording);
    },
    showRecording(context, value) {
      context.commit('setShowRecording', value);
    },
    setRecording(context, value) {
      context.commit('setRecording', value);
    },
    setVersion(context, value) {
      context.commit('setVersion', value);
    },
    setRedirect(context, value) {
      context.commit('setRedirect', value);
    },
    setServer(context, value) {
      context.commit('setServer', value);
    },
    logout(context) {
      context.commit('reset');
    },
    updateMySpeakers(context, value) {
      context.commit('setMySpeakers', _.union(value, [context.state.user.uid]));
    },
    setCurrentLm(context, value) {
      context.commit('setCurrentLm', value);
    }
  },
  modules: {

  },
  getters: {
    getUser: state => {
      return state.user;
    },
    getTokenId: state => {
      return state.tokenId;
    },
    getPublicInfo: state => {
      return state.publicInfo;
    },
    getSpeaker: state => {
      state.speaker.engines = [{ name: "My", url: state.speaker.engines[0].url, enabled: true }]
      return state.speaker;
    },
    getRecording: state => {
      return state.recording;
    }, 
    getShowRecording: state => {
      return state.showRecording;
    },
    getMySpeakers: (state, getters) => {
      return _.union(state.mySpeakers, getters.getUser.uid);
    },
    getSpeakerEngines: state => {
      return {
        My: { name: "My", url: state.speaker.engines[0].url, enabled: true }
      };
    },
    getVersion: state => {
      return state.version;
    },
    getServer: state => {
      return state.server;
    },
    getSelectedLM: state => {
      // return state.currentLm;
      try {
        const id = state.speaker.lm.default.name || "";
        if (id.endsWith("-system")) {
          return {
            id: id,
            name: id.split('-')[0]
          }
        }
        const lms = state.speaker.lm;
        const name = lms[id as keyof typeof lms].name || "";
        return {
          id: id,
          name: name
        }
      } catch (err) {
        return {
          id: "",
          name: ""
        }
      }

      // No longer tracking LM by speaker, 
      // try {
      //   let res = _.get(state.speaker.lm, state.lastChats.chats.get(state.chatToken).lm);
      //   if (res) return res;
      //   return {
      //     id: state.speaker.lm.default.name,
      //     name: _.split(state.speaker.lm.default.name, "-")[0]
      //   }
      // } catch (err) {
      //   return {
      //     id: "",
      //     name: ""
      //   }
      // }
    },
    getRedirect: state => {
      return state.redirect;
    },
    dev: state => {
      return state.user.dev;
    },
    chatToken: state => {
      return state.chatToken;
    },
    lastChats: state => {
      return {
        ts: state.lastChats.ts,
        chats: _.orderBy(
          Array.from(state.lastChats.chats.values()),
          ['ts'],
          ['desc'])
      }
    },
    currentLm: state => {
      return state.currentLm;
    }
  }
});