export default class defaults {
    static user = {
        uid: "self",
        avatar: "https://icons.iconarchive.com/icons/icons8/android/256/Users-User-icon.png",
        displayName: "self",
        admin: "self",
        speaker: "",
        anon: false,
        buddyAPB: false,
        engines: [
            { name: "English", url: "", enabled: false },
            { name: "My", url: "", enabled: false },
            { name: "Keywords", url: "", enabled: false },
        ], 
        googleSTT: true,
        ihearuSTT_enabled: false,
        ihearuSTT_custom_enabled: false,
        ihearuSTT_custom_url: "",
        ihearuSTT_url: "",
        retry: 0,
        stats: {
            corpus: {
                count: 0
            },
            audio: {
                count: 0
            }
        },
        lm: {
            default: {
                id: "default",
                name: "",
                state: "default"
            }
        },
        dev: false,
        contacts: [],
        vadMaxActivity: 20,
        vadMaxInactivity: 2
    };

    static getFilename = (text:string) => {
        try {
            return text.replace(/ /g, "_") + Math.random().toString(10) + ".wav";
        } catch (err) {
            return Math.random().toString(10) + ".wav";
        }
    }

    static projectBasedOnUrl = () => {
        if ((location.hostname.startsWith("ihearu-beta") ||
            location.hostname.startsWith("beta.aihearu.com") ||
            location.hostname.startsWith("beta.ihearu.app"))) {
            return "ihearu-beta";
        }

        if (location.hostname === "localhost") {
            return "localhost";
        }

        if ((location.hostname.startsWith("ihearu-dev") ||
            location.hostname.startsWith("dev.ihearu.app"))) {
            return "ihearu-dev";
        }

        return location.hostname.split('.')[0];
    };


}