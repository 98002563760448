import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import browserDetect from "vue-browser-detect-plugin";
import device from "vue-device-detector"
import EasyRefresh from 'vue-easyrefresh'
import VueCookies from 'vue-cookies'
import VueClipboard from 'vue-clipboard2'
import visibility from "vue-visibility-change";

Vue.use(VueClipboard)
Vue.use(visibility);

Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(VueAxios, axios)
Vue.use(browserDetect);
Vue.use(device);
Vue.use(EasyRefresh)

export const bus = new Vue();
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
